import * as types from './mutation-types'

export default {
  [types.SET_ORGANIZATIONAL_STRUCTURES] (state, payload) {
    state.organizational_structures = payload
  },
  [types.SET_STRUCTURE_ITEM] (state, payload) {
    state.structure_item = payload
  },
  [types.SET_TREE_STRUCTURE] (state, payload) {
    state.tree_structure = payload
  },
  [types.SET_PAGINATION] (state, payload) {
    state.pagination = payload
  }
}
