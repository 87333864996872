import numeral from "numeral";

numeral.register("locale", "pt-BR", {
  delimiters: {
    thousands: ".",
    decimal: ","
  },
  currency: {
    symbol: "R$"
  }
});
numeral.locale("pt-BR");

export default function(value, format = "0,000.00") {
  if (typeof value == "number") {
    value = numeral(value).format(format);
  }
  return value;
}
