import store from "@/store";

export const GetPagination = ({ pagination }) => pagination;
export const GetRole = ({ role }) => role;

export const GetCan = () => (permission) => {
  if (!permission) {
    return true;
  }

  let user = store.getters["auth/GetUser"];
  if (
    user.roles.indexOf(process.env.VUE_APP_ROLE_ADMIN) > -1 ||
    user.permissions.indexOf(permission) > -1
  ) {
    return true;
  }
  return false;
};

export const GetIsSuperAdmin = () => {
  let user = store.getters["auth/GetUser"];
  return user.roles.indexOf(process.env.VUE_APP_ROLE_ADMIN) > -1;
};
