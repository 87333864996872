import * as types from "./mutation-types";

export default {
  [types.SET_INDICATOR](state, payload) {
    state.indicator = payload;
  },
  [types.SET_INDICATORS](state, payload) {
    state.indicators = payload;
  },
  [types.SET_PAGINATION](state, payload) {
    state.pagination = payload;
  }
};
