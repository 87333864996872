export default [
  {
    path: "/planos-estrategicos",
    component: () => import("@/layout/Default"),
    children: [
      {
        name: "planos_estrategicos_list",
        path: "",
        component: () => import("./pages/PublicList"),
        meta: {
          title: "Planos Municipais",
          breadcrumbs: [
            // { label: "Planos Municipais", to: "planos_estrategicos_routes" }
          ],
          permission: "strategic_plan:view"
        }
      }
    ]
  }
];
