import services from "@/http";
import * as types from "./mutation-types";

export const ActionGetAll = async ({ dispatch }, payload) => {
  await services.source.get_all(payload).then(res => {
    dispatch("ActionSetSources", res.data.data);
    dispatch("ActionSetPagination", res.data.meta);
  });
};

export const ActionShow = async ({ dispatch }, payload) => {
  await services.source.show(payload).then(res => {
    dispatch("ActionSetSource", res.data.source);
  });
};

export const ActionFindById = async (store, id) => {
  var person = {};
  try {
    await services.source.show({ id: id }).then(res => {
      person = res.data.source;
    });
  } catch (e) {
    //ignore
  }
  return person;
};

export const ActionSave = async ({ dispatch }, payload) => {
  if (payload.id) {
    await dispatch("ActionUpdate", payload);
  } else {
    await dispatch("ActionStore", payload);
  }
};

export const ActionStore = async ({ commit }, payload) => {
  await services.source.store(payload).then(res => {
    commit(types.SET_SOURCE, res.data.source);
  });
};

export const ActionUpdate = async ({ commit }, payload) => {
  await services.source.update({ id: payload.id }, payload).then(res => {
    commit(types.SET_SOURCE, res.data.source);
  });
};

export const ActionDelete = async ({ commit }, id) => {
  await services.source.delete({ id: id }).then(() => {
    commit(types.SET_SOURCE, null);
  });
};

export const ActionSetSources = ({ commit }, payload) => {
  commit(types.SET_SOURCES, payload);
};

export const ActionSetSource = ({ commit }, payload) => {
  commit(types.SET_SOURCE, payload);
};

export const ActionSetPagination = ({ commit }, payload) => {
  commit(types.SET_PAGINATION, payload);
};

export const ActionFind = async (store, search) => {
  var sources = [];
  await services.source.get_all({ q: search }).then(res => {
    sources = res.data.data;
  });
  return sources;
};

export const ActionNew = async ({ dispatch }) => {
  let source = {
    name: "",
    description: ""
  };
  dispatch("ActionSetSource", source);
};
