import services from "@/http";
import * as types from "./mutation-types";

export const ActionGetAll = async ({ dispatch }, payload) => {
  await services.states.get_all(payload).then(res => {
    dispatch("ActionSetStates", res.data.data);
    dispatch("ActionSetPagination", res.data.meta);
  });
};

export const ActionShow = async ({ dispatch }, payload) => {
  await services.states.show(payload).then(res => {
    dispatch("ActionSetState", res.data.state);
  });
};

export const ActionStore = async ({ commit }, payload) => {
  await services.states.store(payload).then(res => {
    commit(types.SET_STATE, res.data.state);
  });
};

export const ActionUpdate = async ({ commit }, payload) => {
  const id = {id:payload.initials};
  await services.states.update(id,payload).then(res => {
    commit(types.SET_STATE, res.data.state);
  });
};

export const ActionDelete = async ({ commit }, payload) => {
  await services.states.delete(payload).then(() => {
    commit(types.SET_STATE, null);
  });
};

export const ActionSetStates = ({ commit }, payload) => {
  commit(types.SET_STATES, payload);
};

export const ActionSetState = ({ commit }, payload) => {
  commit(types.SET_STATE, payload);
};

export const ActionSetPagination = ({ commit }, payload) => {
  commit(types.SET_PAGINATION, payload);
};

