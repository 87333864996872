export default {
  get_all: { method: "get", url: "api/objectives" },
  show: { method: "get", url: "api/objectives{/id}" },
  store: { method: "post", url: "api/objectives" },
  update: { method: "put", url: "api/objectives{/id}" },
  delete: { method: "delete", url: "api/objectives{/id}" },
  get_ods: { method: "get", url: "api/objectives/ods" },
  verify_indicator: { method: "get", url: "api/objectives/verify-indicator" },
  orderMeasuresById: {
    method: "post",
    url: "api/measures/order{/id}"
  },
  get_measures: { method: "get", url: "api/measures"}
};
