import services from "@/http";
import * as types from "./mutation-types";

export const ActionGetAll = async ({ dispatch }, payload) => {
  await services.legal_people.get_all(payload).then(res => {
    dispatch("ActionSetLegalPeople", res.data.data);
    dispatch("ActionSetPagination", res.data.meta);
  });
};

export const ActionShow = async ({ dispatch }, payload) => {
  await services.legal_people.show(payload).then(res => {
    dispatch("ActionSetLegalPerson", res.data.legal_person);
  });
};

export const ActionFindById = async (store, id) => {
  var person = {};
  await services.legal_people.show({ id: id }).then(res => {
    person = res.data.legal_person;
  });
  return person;
};

export const ActionFind = async (store, search) => {
  var people = [];
  await services.legal_people.get_all({ q: search }).then(res => {
    people = res.data.data;
  });
  return people;
};


export const ActionSave = async ({ dispatch }, payload) => {
  if (payload.id) {
    await dispatch("ActionUpdate", payload);
  } else {
    await dispatch("ActionStore", payload);
  }
};

export const ActionStore = async ({ commit }, payload) => {
  await services.legal_people.store(payload).then(res => {
    commit(types.SET_LEGAL_PERSON, res.data.legal_person);
  });
};

export const ActionUpdate = async ({ commit }, payload) => {
  await services.legal_people.update({ id: payload.id }, payload).then(res => {
    commit(types.SET_LEGAL_PERSON, res.data.legal_person);
  });
};

export const ActionDelete = async ({ commit }, id) => {
  await services.legal_people.delete({id: id}).then(() => {
    commit(types.SET_LEGAL_PERSON, null);
  });
};

export const ActionSetLegalPeople = ({ commit }, payload) => {
  commit(types.SET_LEGAL_PEOPLE, payload);
};

export const ActionSetLegalPerson = ({ commit }, payload) => {
  commit(types.SET_LEGAL_PERSON, payload);
};

export const ActionSetPagination = ({ commit }, payload) => {
  commit(types.SET_PAGINATION, payload);
};

export const ActionNew = async ({ dispatch }) => {
  let legal_person = {
    social_reason: "",
    fantasy_name: "",
    cnpj: "",
    ie: null,
    address: {
      street: "",
      number: "",
      neighborhood: "",
      zip_code: "",
      complement: "",
      county_id: null
    }
  };
  dispatch("ActionSetLegalPerson", legal_person);
};
