import * as types from './mutation-types'

export default {
  [types.SET_USER] (state, payload) {
    state.user = payload
  },
  [types.SET_USERS] (state, payload) {
    state.users = payload
  },
  [types.SET_ROLES] (state, payload) {
    state.roles = payload
  },
  [types.SET_PAGINATION] (state, payload) {
    state.pagination = payload
  }
}
