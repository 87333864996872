import Notify from "@/components/NotificationPlugin";
import Config from "@/components/ConfigPlugin";
import { BootstrapVue, IconsPlugin } from "bootstrap-vue";
import VueTheMask from 'vue-the-mask'

import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-vue/dist/bootstrap-vue.css";

import "../assets/scss/app.scss";
import "../helpers/filters";
import "../helpers/fonts";
import "../helpers/functions";
//import "../helpers/directives";
import "../components/Form";


import InfiniteLoading from 'vue-infinite-loading';

export default {
  install(Vue) {
    Vue.use(Notify);
    Vue.use(Config);
    Vue.use(BootstrapVue);
    Vue.use(IconsPlugin);
    Vue.use(VueTheMask);
    Vue.use(InfiniteLoading);
  }
};
