import services from "@/http";
import * as types from "./mutation-types";

export const ActionGetAll = async ({ commit }, payload) => {
  try {
    const { data } = await services.action_plans.get_all({
      objective_id: payload.id,
    });
    commit(types.SET_PLANS, data);
    return data;
  } catch (error) {
    return {};
  }
};

export const ActionGetAllItems = async ({ commit }, payload) => {
  try {
    const { data } = await services.action_plans.get_plans_items({
      action_plan_id: payload.id,
    });
    return data;
  } catch (error) {
    return {};
  }
};

export const ActionGetOnePlan = async ({ commit }, payload) => {
  try {
    const { data } = await services.action_plans.get_action_plan({
      id: payload.id,
    });
    commit(types.SET_PLAN, data.action_plan);
    return data;
  } catch (error) {}
};

export const ActionSave = async ({ dispatch }, payload) => {
  if (payload.id) {
    await dispatch("ActionUpdate", payload);
    return;
  }
  await dispatch("ActionStore", payload);
};

export const ActionStore = async ({ commit }, payload) => {
  try {
    const { data } = await services.action_plans.store(payload);
    await commit(types.SET_PLAN, data.action_plan);
  } catch (error) {}
};

export const ActionSavePlanItem = async ({ dispatch }, payload) => {
  if (payload.id) {
    dispatch("ActionUpdatePlanItem", payload);
    return;
  }
  dispatch("ActionStorePlanItem", payload);
};

export const ActionSaveHowMuchItems = async ({ dispatch }, payload) => {
  if (payload.id) {
    dispatch("ActionUpdateHowMuchItems", payload);
    return;
  }
  dispatch("ActionStoreHowMuchItems", payload);
};

export const ActionUpdateHowMuchItems = async ({ commit }, payload) => {
  try {
    const { data } = await services.action_plans.update_how_much_items(
      { id: payload.id },
      payload
    );
    commit(types.SET_PLAN_HOW_MUCH, data);
  } catch (error) {}
};

export const ActionStoreHowMuchItems = async ({ commit }, payload) => {
  try {
    const { data } = await services.action_plans.store_how_much_items(payload);
    commit(types.SET_PLAN_HOW_MUCH, data);
  } catch (error) {}
};

export const ActionStorePlanItem = async ({ commit }, payload) => {
  try {
    const { data } = await services.action_plans.store_plan_items(payload);
    commit(types.SET_PLAN_ITEMS, data);
  } catch (_) {}
};

export const ActionUpdatePlanItem = async ({ commit }, payload) => {
  try {
    const { data } = await services.action_plans.update_plan_items(
      { id: payload.id },
      payload
    );
    commit(types.SET_PLAN_ITEMS, data);
  } catch (error) {}
};

export const ActionUpdate = async ({ commit }, payload) => {
  try {
    const { data } = await services.action_plans.update(
      { id: payload.id },
      payload
    );
    commit(types.SET_PLAN, data.action_plan);
  } catch (error) {}
};

export const ActionDeleteActionPlan = async ({ commit }, payload) => {
  try {
    const result = await services.action_plans.delete_action_plan({
      id: payload.id,
    });
    return result;
  } catch (error) {}
};
