import * as types from "./mutation-types";

export default {
  [types.SET_OBJECTIVES](state, payload) {
    state.objectives = payload;
  },
  [types.SET_OBJECTIVE](state, payload) {
    state.objective = payload;
  },
  [types.SET_PAGINATION](state, payload) {
    state.pagination = payload;
  },
  [types.SET_ODS](state, payload) {
    state.ods = payload;
  },
  [types.SET_MEASURES] (state, payload) {
    state.objective.measures = payload;
  }
};
