export default [
  {
    path: "/legal_people",
    redirect: "legal_people_list",
    component: () => import("@/layout/Default"),
    children: [
      {
        name: "legal_people_list",
        path: "",
        component: () => import("./pages/List"),
        meta: {
          title: "Pessoas Jurídicas",
          breadcrumbs: [
            {label: "Pessoas Jurídicas", to: "legal_people_list"}
          ],         
          permission: "legal_people:view"
        }
      },
      {
        name: "legal_people_show",
        path: "show/:id",
        component: () => import("./pages/Show"),
        meta: {
          title: "Pessoas Jurídicas | Visualizar",
          breadcrumbs: [
            {label: "Pessoas Jurídicas", to: "legal_people_list"},
            {label: "Visualizar", to: "legal_people_show"}
          ],         
          permission: "legal_people:view"
        }
      },
      {
        name: "legal_people_create",
        path: "create",
        component: () => import("./pages/Create"),
        meta: {
          title: "Pessoa Jurídica | Cadastro",
          breadcrumbs: [
            {label: "Pessoas Jurídicas", to: "legal_people_list"},
            {label: "Novo", to: "legal_people_create"}
          ],         
          permission: "legal_people:create"
        }
      },
      {
        name: "legal_people_edit",
        path: "edit/:id",
        component: () => import("./pages/Edit"),
        meta: {
          title: "Pessoa Jurídica | Editar",
          breadcrumbs: [
            {label: "Pessoas Jurídicas", to: "legal_people_list"},
            {label: "Editar", to: "legal_people_edit"}
          ],         
          permission: "legal_people:edit"
        }
      }
    ]
  }
];
