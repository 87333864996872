export default function(document) {
  if (document) {
    document = typeof document != "string" ? document.toString() : document;

    document = document.padStart(11, "0");
    document = document.replace(/^(\d{3})(\d{3})(\d{3})(\d{2})/, "$1.$2.$3-$4");
  }

  return document;
}
