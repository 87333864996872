export default [
  {
    path: "/acl",
    component: () => import("@/layout/Default"),
    children: [
      {
        name: "acl_list",
        path: "",
        component: () => import("./pages/List"),
        meta: {
          title: "Controle de Acesso",
          breadcrumbs: [{ label: "Controle de Acesso", to: "acl_list" }],
          permission: 'acl:view'
        }
      },
      {
        name: "acl_show",
        path: "show/:id",
        component: () => import("./pages/Show"),
        meta: {
          title: "Controle de Acesso | Papel",
          breadcrumbs: [
            { label: "Controle de Acesso", to: "acl_list" },
            {
              label: "Visualizar",
              to: "show",
              params: {
                getter: "acl/GetRole",
                id: "role.id"
              }
            }
          ],
          permission: 'acl:view'
        }
      },
      {
        name: "acl_create",
        path: "create",
        component: () => import("./pages/Create"),
        meta: {
          title: "Controle de Acesso | Cadastro de Papel",
          breadcrumbs: [
            { label: "Controle de Acesso", to: "acl_list" },
            { label: "Novo", to: "create" }
          ],
          action: "Criando papel",
          permission: 'acl:create'
        }
      },
      {
        name: "acl_edit",
        path: "edit/:id",
        component: () => import("./pages/Edit"),
        meta: {
          title: "Controle de Acesso | Edição de Papel",
          breadcrumbs: [
            { label: "Controle de Acesso", to: "acl_list" },
            {
              label: "Editar",
              to: "edit",
              params: {
                getter: "acl/GetRole",
                id: "role.id"
              }
            }
          ],         
          action: "Editando papel",
          permission: 'acl:edit'
        }
      }
    ]
  }
];
