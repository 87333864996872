import * as types from "./mutation-types";

export default {
  [types.SET_PLANS](state, payload) {
    state.plans = payload;
  },
  [types.SET_PLAN](state, payload) {
    state.action_plan = payload;
  },
  [types.SET_PLAN_ITEMS](state, payload) {
    state.plan_items = payload;
  },
};
