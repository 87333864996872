import services from "@/http";
import * as types from "./mutation-types";

export const ActionGetAll = async ({ dispatch }, payload) => {
  await services.unities.get_all(payload).then(res => {
    dispatch("ActionSetUnities", res.data.data);
    dispatch("ActionSetPagination", res.data.meta);
  });
};

export const ActionFind = async (store, payload) => {
  var response = [];
  await services.unities.get_all(payload).then(res => {
    response = res.data;
  });
  return response;
};

export const ActionShow = async ({ dispatch }, payload) => {
  await services.unities.show(payload).then(res => {
    dispatch("ActionSetUnity", res.data.unity);
  });
};

export const ActionFindById = async (store, id) => {
  var unity = {};
  try {
    await services.unities.show({ id: id }).then(res => {
      unity = res.data.unity;
    });
  } catch (e) {
    //ignore
  }
  return unity;
};

export const ActionSave = async ({ dispatch }, payload) => {
  if (payload.id) {
    await dispatch("ActionUpdate", payload);
  } else {
    await dispatch("ActionStore", payload);
  }
};

export const ActionStore = async ({ commit }, payload) => {
  await services.unities.store(payload).then(res => {
    commit(types.SET_UNITY, res.data.unity);
  });
};

export const ActionUpdate = async ({ commit }, payload) => {
  await services.unities.update({ id: payload.id }, payload).then(res => {
    commit(types.SET_UNITY, res.data.unity);
  });
};

export const ActionDelete = async ({ commit }, id) => {
  await services.unities.delete({ id: id }).then(() => {
    commit(types.SET_UNITY, null);
  });
};

export const ActionSetUnities = ({ commit }, payload) => {
  commit(types.SET_UNITIES, payload);
};

export const ActionSetUnity = ({ commit }, payload) => {
  commit(types.SET_UNITY, payload);
};

export const ActionSetPagination = ({ commit }, payload) => {
  commit(types.SET_PAGINATION, payload);
};

export const ActionNew = async ({ dispatch }) => {
  let unity = {
    name: "",
    initials: ""
  };
  dispatch("ActionSetUnity", unity);
};
