export default [
  {
    path: "/",
    component: () => import("@/layout/Default"),
    children: [
      {
        name: "home",
        path: "",
        component: () => import("./Home"),
        meta: {
          title: "Home",
          isPam: true,
          breadcrumbs: []
        }
      }
    ]
  }
];
