import * as types from "./mutation-types";

export default {
  [types.SET_STRATEGIC_PLANS](state, payload) {
    state.strategic_plans = payload;
  },
  [types.SET_STRATEGIC_PLAN](state, payload) {
    state.strategic_plan = payload;
  },
  [types.SET_PAGINATION](state, payload) {
    state.pagination = payload;
  },
  [types.SET_TREE_PLAN_STRUCTURE](state, payload) {
    state.tree_plan_structure = payload;
  },
  [types.SET_PLAN_OBJECTIVES](state, payload) {
    state.plan_objectives = payload;
  },
  [types.ADD_PLAN_OBJECTIVE](state, objective) {
    state.plan_objectives.push(objective);
  }
};
