export const GetPlans = ({ plans }) => plans;
export const GetNamePlan = ({ action_plan }) => action_plan?.name ?? "";
export const GetIdPlan = ({ action_plan }) => action_plan?.id;
export const GetObjectiveName = ({ action_plan }) =>
  action_plan?.objective?.name ?? "";
export const GetObjectiveId = ({ action_plan }) => action_plan?.objective?.id;
export const GetMappedStatus = () => ({
  planned: { title: "Planejado", color: "info" },
  "in-progress": { title: "Em progresso", color: "warning" },
  finished: { title: "Finalizado", color: "success" },
  cancelled: { title: "Cancelado", color: "secondary" },
  delayed: { title: "Atrasado", color: "danger" },
  stopped: { title: "Paralizado", color: "deep-orange" },
  waiting: { title: "Aguardando", color: "orange" },
});
