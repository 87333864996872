export default [
  {
    path: "/organizational_structures",
    component: () => import("@/layout/Default"),
    children: [
      {
        name: "organizational_structures_list",
        path: "list",
        component: () => import("./pages/List"),
        meta: {
          breadcrumbs: [
            {
              label: "Estruturas Organizacionais",
              to: "organizational_structures_list"
            }
          ],
          permission: "organizational_structure:view"
        }
      },
      {
        name: "organizational_structures_tree",
        path: "",
        component: () => import("./pages/TreeStructure"),
        meta: {
          breadcrumbs: [
            {
              label: "Estruturas Organizacionais",
              to: "organizational_structures_list"
            }
          ],
          permission: "organizational_structure:view"
        }
      },
      {
        name: "organizational_structures_show",
        path: "show/:id",
        component: () => import("./pages/Show"),
        meta: {
          breadcrumbs: [
            {
              label: "Estruturas Organizacionais",
              to: "organizational_structures_list"
            },
            { label: "Visualizar", to: "organizational_structures_show" }
          ],
          permission: "organizational_structure:view"
        }
      },
      {
        name: "organizational_structures_create",
        path: "create",
        component: () => import("./pages/Create"),
        meta: {
          breadcrumbs: [
            {
              label: "Estruturas Organizacionais",
              to: "organizational_structures_list"
            },
            { label: "Novo", to: "organizational_structures_create" }
          ],
          permission: "organizational_structure:create"
        }
      },
      {
        name: "organizational_structures_edit",
        path: "edit/:id",
        component: () => import("./pages/Edit"),
        meta: {
          breadcrumbs: [
            {
              label: "Estruturas Organizacionais",
              to: "organizational_structures_list"
            },
            { label: "Editar", to: "organizational_structures_edit" }
          ],
          permission: "organizational_structure:edit"
        }
      }
    ]
  }
];
