import services from "@/http";
import * as types from "./mutation-types";

export const ActionGetAll = async ({ dispatch }, payload) => {
  await services.users.get_all(payload).then((res) => {
    dispatch("ActionSetUsers", res.data.data);
    dispatch("ActionSetPagination", res.data.meta);
  });
};

export const ActionShow = async ({ dispatch }, payload) => {
  await services.users.show(payload).then((res) => {
    dispatch("ActionSetUser", res.data.user);
  });
};

export const ActionFindById = async (store, id) => {
  var user = {};
  try {
    await services.users.show({ id: id }).then((res) => {
      user = res.data.user;
    });
  } catch (e) {
    //ignore
  }
  return user;
};

export const ActionSave = async ({ dispatch }, payload) => {
  if (payload.id) {
    await dispatch("ActionUpdate", payload);
  } else {
    await dispatch("ActionStore", payload);
  }
};

export const ActionStore = async ({ commit }, payload) => {
  delete payload.isProfile;
  await services.users.store(payload).then((res) => {
    commit(types.SET_USER, res.data.user);
  });
};

export const ActionUpdate = async ({ commit }, payload) => {
  var params = { id: payload.id };
  if (payload.isProfile) {
    params.isProfile = true;
  }

  delete payload.isProfile;
  await services.users.update(params, payload).then((res) => {
    commit(types.SET_USER, res.data.user);
  });
};

export const ActionDelete = async ({ commit }, id) => {
  await services.users.delete({ id: id }).then(() => {
    commit(types.SET_USER, null);
  });
};

export const ActionSetUsers = ({ commit }, payload) => {
  commit(types.SET_USERS, payload);
};

export const ActionSetUser = ({ commit }, payload) => {
  let idRoles = payload.roles.map((role) => role.id);
  payload.roles = idRoles;
  commit(types.SET_USER, payload);
};

export const ActionSetPagination = ({ commit }, payload) => {
  commit(types.SET_PAGINATION, payload);
};

export const ActionFind = async (store, search) => {
  var responsibles = [];
  await services.users.get_all({ q: search }).then((res) => {
    responsibles = res.data.data;
  });
  return responsibles;
};

export const ActionGetAllRoles = async ({ dispatch }, payload) => {
  await services.users.get_all_roles(payload).then((res) => {
    dispatch("ActionSetRoles", res.data.data);
  });
};

export const ActionSetRoles = ({ commit }, payload) => {
  commit(types.SET_ROLES, payload);
};

export const ActionNew = async ({ dispatch }) => {
  let user = {
    name: "",
    email: "",
    created_at: "",
    updated_at: "",
    roles: [],
  };
  dispatch("ActionSetUser", user);
};

export const ActionVerify = async (store, email) => {
  var user = null;
  await services.users.verify({ email: email }).then((res) => {
    user = res.data.user;
  });
  return user;
};

export const ActionJoin = async (store, id) => {
  await services.users.join({ id: id }, {});
};
