export default [
  {
    path: "/indicators",
    component: () => import("@/layout/Default"),
    children: [
      {
        name: "indicators_list",
        path: "",
        component: () => import("./pages/List"),
        meta: {
          title: "Indicadores",
          breadcrumbs: [{ label: "Indicadores", to: "indicators_list" }],
          permission: "indicator:view"
        }
      },
      {
        name: "indicators_show",
        path: "show/:id",
        component: () => import("./pages/Show"),
        meta: {
          title: "Indicadores",
          breadcrumbs: [
            { label: "Indicadores", to: "indicators_list" },
            {
              label: "Visualizar",
              to: "show",
              params: {
                getter: "indicators/GetIndicator",
                id: "indicator.id"
              }
            }
          ],
          permission: "indicator:view"
        }
      },
      {
        name: "indicators_create",
        path: "create",
        component: () => import("./pages/Create"),
        meta: {
          title: "Indicadores | Cadastro de indicadores",
          breadcrumbs: [
            { label: "Indicadores", to: "indicators_list" },
            { label: "Novo", to: "create" }
          ],
          action: "Criando indicador",
          permission: "indicator:create"
        }
      },
      {
        name: "indicators_edit",
        path: "edit/:id",
        component: () => import("./pages/Edit"),
        meta: {
          title: "Indicadores | Edição de indicador",
          breadcrumbs: [
            { label: "Indicadores", to: "indicators_list" },
            {
              label: "Editar",
              to: "edit",
              params: {
                getter: "indicators/GetIndicator",
                id: "indicator.id"
              }
            }
          ],
          action: "Editando indicador",
          permission: "indicator:edit"
        }
      }
    ]
  }
];
