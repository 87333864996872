export default {
  get_all: { method: "get", url: "api/organizational-structures" },
  show: { method: "get", url: "api/organizational-structures{/id}" },
  store: { method: "post", url: "api/organizational-structures" },
  update: { method: "put", url: "api/organizational-structures{/id}" },
  delete: { method: "delete", url: "api/organizational-structures{/id}" },
  tree_structure: {
    method: "get",
    url: "api/organizational-structures/tree-structure"
  },
  add_physical_people: {
    method: "post",
    url: "api/organizational-structures/add-physical-people{/id}"
  },
  delete_physical_people: {
    method: "delete",
    url: "api/organizational-structures/remove-physical-people{/id}"
  }
};
