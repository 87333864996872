export const getPagination = ({ pagination }) => pagination;
export const getStrategicPlans = ({ homeData }) => homeData.strategic_plans;
export const getStrategicStatus = ({ homeData }) =>
  homeData.strategic_plans_status;
export const getSeriesData = ({ homeData }) => {
  try {
    const strategicStatus = getStrategicStatus({ homeData }) || {};
    return labels =>
      Object.keys(strategicStatus).map(key => ({
        name: labels[key].label,
        value: strategicStatus[key],
        description: labels[key].showOnHover,
        variantColor: labels[key].variantColor
      }));
  } catch (error) {
    return [];
  }
};
