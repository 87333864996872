import * as types from './mutation-types'

export default {
  [types.SET_COUNTIES] (state, payload) {
    state.counties = payload
  },
  [types.SET_COUNTY] (state, payload) {
    state.county = payload
  },
  [types.SET_PAGINATION] (state, payload) {
    state.pagination = payload
  }
}
