import _ from "lodash";
export const getPagination = ({ pagination }) => pagination;

export const getPayLoadPlanStructure = state => {
  var recursive = plan_structures => {
    var response = [];
    plan_structures.forEach(plan_structure => {
      var objectives = _.filter(state.plan_objectives, function(objective) {
        return objective.plan_structure_id == plan_structure.id;
      });

      if (objectives.length) {
        plan_structure.objectives = objectives;
      }

      if (Array.isArray(plan_structure.children)) {
        plan_structure.children = recursive(plan_structure.children);
      }
      response.push(plan_structure);
    });
    return response;
  };
  return recursive(state.tree_plan_structure);
};

export const getTreePlanStructureLength = state => {
  var recursive = plan_structures => {
    var response = plan_structures.length;
    if (plan_structures.length) {
      plan_structures.forEach(plan_structure => {
        if (Array.isArray(plan_structure.children)) {
          response += recursive(plan_structure.children);
        }
      });
    }
    return response;
  };

  return state.tree_plan_structure ? recursive(state.tree_plan_structure) : 0;
};

export const getMeasuresLength = state => {
  var metas = 0;
  state.plan_objectives.forEach(objective => {
    metas += objective.measures.length;
  });

  return metas;
};

export const getIndicatorsLength = state => {
  var indicators = 0;
  state.plan_objectives.forEach(objective => {
    indicators += objective.indicators.length;
  });

  return indicators;
};
