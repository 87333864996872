import services from "@/http";
import * as types from "./mutation-types";

export const ActionGetAll = async ({ dispatch }, payload) => {
  await services.strategic_plans.get_all(payload).then((res) => {
    dispatch("ActionSetStrategicPlans", res.data.data);
    dispatch("ActionSetPagination", res.data.meta);
  });
};

export const ActionShow = async ({ dispatch }, payload) => {
  await services.strategic_plans.show(payload).then((res) => {
    dispatch("ActionSetStrategicPlan", res.data.strategic_plan);
  });
};

/**
 * @param {*} store
 * @param {*} payload
 *
 * Ações de SAVE
 */
export const ActionSave = async ({ dispatch }, payload) => {
  if (payload.id) {
    await dispatch("ActionUpdate", payload);
  } else {
    await dispatch("ActionStore", payload);
  }
};

export const ActionStore = async ({ commit }, payload) => {
  await services.strategic_plans.store(payload).then(async (res) => {
    await commit(types.SET_STRATEGIC_PLAN, res.data.strategic_plan);
  });
};

export const ActionUpdate = async ({ commit }, payload) => {
  await services.strategic_plans
    .update({ id: payload.id }, payload)
    .then((res) => {
      commit(types.SET_STRATEGIC_PLAN, res.data.strategic_plan);
    });
};

export const ActionSaveTreeStructure = async ({ state, commit }, structure) => {
  if (state.strategic_plan.id) {
    await services.strategic_plans
      .save_structure({ id: state.strategic_plan.id }, structure)
      .then(async (res) => {
        await commit(types.SET_TREE_PLAN_STRUCTURE, res.data);
      });
  }
};

/*************************************************************/

export const ActionDelete = async ({ commit }, id) => {
  await services.strategic_plans.delete({ id: id }).then(() => {
    commit(types.SET_STRATEGIC_PLAN, null);
  });
};

export const ActionSetStrategicPlans = ({ commit }, payload) => {
  commit(types.SET_STRATEGIC_PLANS, payload);
};

export const ActionSetStrategicPlan = ({ commit }, payload) => {
  commit(types.SET_STRATEGIC_PLAN, payload);
};

export const ActionSetPagination = ({ commit }, payload) => {
  commit(types.SET_PAGINATION, payload);
};

export const ActionLoadPlanStructure = async ({ state, dispatch, commit }) => {
  var tree_plan_structure = await dispatch(
    "ActionGetPlanStructure",
    state.strategic_plan.id
  );
  commit(types.SET_TREE_PLAN_STRUCTURE, tree_plan_structure);
};

export const ActionGetPlanStructure = async (store, strategic_plan_id) => {
  var tree_plan_structure = [];
  await services.strategic_plans
    .tree_plan_structure({ id: strategic_plan_id })
    .then((res) => {
      tree_plan_structure = res.data;
    });
  return tree_plan_structure;
};

export const ActionGetObjectives = async ({ commit }, id) => {
  var objectives = [];
  await services.strategic_plans.get_objectives({ id: id }).then((res) => {
    objectives = res.data.objectives;
  });
  commit(types.SET_PLAN_OBJECTIVES, objectives);
};

export const ActionSetNew = ({ commit }) => {
  var strategic_plan = {
    id: null,
    responsible_id: {},
    organizational_structure_id: null,
    active: true,
    name: "",
    description: "",
    start_term: "",
    end_term: "",
    icon: "",
    legal_instrument: "",
    legal_instrument_date: "",
    legal_instrument_link: "",
    config: {
      plan_structure: {
        levels: ["Perspectiva", "Diretriz"],
        status: "yellow",
      },
    },
    responsible: null,
    organizationalStructure: null,
  };
  commit(types.SET_STRATEGIC_PLAN, strategic_plan);
  commit(types.SET_TREE_PLAN_STRUCTURE, []);
};

export const ActionOrderObjectivesById = async (store, payload) => {
  await services.strategic_plans.orderObjectivesById(
    { id: payload.id },
    payload.objectives
  );
};

export const ActionOrderDiretrizById = async (store, payload) => {
  await services.strategic_plans.orderDiretrizesById(
    { id: payload.id },
    payload.diretrizes
  );
};

export const ActionOrderStrategicPlansById = async (store, payload) => {
  await services.strategic_plans.orderStrategicPlansById(
    payload.ordered_strategic_plans
  );
};
