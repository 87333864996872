import * as types from "./mutation-types";

export default {
  [types.SET_CLIENTS](state, payload) {
    state.clients = payload;
  },
  [types.SET_CLIENT](state, payload) {
    state.client = payload;
  },
  [types.SET_PAGINATION](state, payload) {
    state.pagination = payload;
  },
  [types.SET_CLIENTTOUPDATE](state, payload) {
    state.clientToUpdate = payload;
  },
  [types.SET_HOMEDATA](state, payload) {
    state.homeData = payload;
  }
};
