import services from "@/http";
import * as types from "./mutation-types";
import { setClientConfig, getClientConfig } from "../storage";
export const ActionGetAll = async ({ dispatch }, payload) => {
  await services.clients.get_all(payload).then((res) => {
    dispatch("ActionSetClients", res.data.data);
    dispatch("ActionSetPagination", res.data.meta);
  });
};

export const ActionShow = async ({ dispatch }, payload) => {
  await services.clients.show(payload).then((res) => {
    dispatch("ActionSetClient", res.data.client);
  });
};

export const ActionFindById = async (store, id) => {
  var client = {};
  await services.clients.show({ id: id }).then((res) => {
    client = res.data.client;
  });
  return client;
};

export const ActionUpdate = async ({ commit }, payload) => {
  await services.clients.update(payload).then((res) => {
    commit(types.SET_CLIENT, res.data.client);
  });
};

export const ActionSetClients = ({ commit }, payload) => {
  commit(types.SET_CLIENTS, payload);
};

export const ActionSetClient = ({ commit }, payload) => {
  commit(types.SET_CLIENT, payload);
};

export const ActionSetPagination = ({ commit }, payload) => {
  commit(types.SET_PAGINATION, payload);
};

export const ActionSetClientToUpdate = ({ commit }, payload) => {
  commit(types.SET_CLIENTTOUPDATE, payload);
};

export const ActionLoadConfig = async () => {
  let response = getClientConfig();

  if (!response) {
    await services.clients.load_config().then((res) => {
      response = res.data;
      setClientConfig(res.data);
    });
  } else {
    services.clients.load_config().then((res) => {
      setClientConfig(res.data);
    });
  }

  return response;
};

export const ActionLoadDataHome = async ({ commit }) => {
  try {
    await services.clients.data_home().then((res) => {
      commit(types.SET_HOMEDATA, res.data);
      return res;
    });
  } catch (error) {

    return {};
  }
};
