export default [
  {
    path: "/units",
    component: () => import("@/layout/Default"),
    children: [
      {
        name: "units_list",
        path: "",
        component: () => import("./pages/List"),
        meta: {
          title: "Unidades",
          breadcrumbs: [{ label: "Unidades", to: "units_list" }],
          permission: "unity:view"
        }
      },
      {
        name: "units_show",
        path: "show/:id",
        component: () => import("./pages/Show"),
        meta: {
          title: "Unidades",
          breadcrumbs: [
            { label: "Unidades", to: "units_list" },
            {
              label: "Visualizar",
              to: "show",
              params: {
                getter: "units/GetUnity",
                id: "unity.unit_id"
              }
            }
          ],
          permission: "unity:view"
        }
      },
      {
        name: "units_create",
        path: "create",
        component: () => import("./pages/Create"),
        meta: {
          title: "Unidades | Cadastro de unidade",
          breadcrumbs: [
            { label: "Unidades", to: "units_list" },
            { label: "Novo", to: "create" }
          ],
          action: "Criando unidade",
          permission: "unity:create"
        }
      },
      {
        name: "units_edit",
        path: "edit/:id",
        component: () => import("./pages/Edit"),
        meta: {
          title: "Unidades | Edição de unidade",
          breadcrumbs: [
            { label: "Unidades", to: "units_list" },
            {
              label: "Editar",
              to: "edit",
              params: {
                getter: "units/GetUnity",
                id: "unity.unity_id"
              }
            }
          ],
          action: "Editando unidade",
          permission: "unity:edit"
        }
      }
    ]
  }
];
