import services from "@/http";
import * as types from "./mutation-types";

export const ActionGetAll = async ({ dispatch }, payload) => {
  await services.measures.get_all(payload).then(res => {
    dispatch("ActionSetMeasures", res.data.data);
    dispatch("ActionSetPagination", res.data.meta);
  });
};

export const ActionShow = async ({ dispatch }, id) => {
  await services.measures.show({ id: id }).then(res => {
    let measure = res.data.measure;
    if (
      !measure.chart_config ||
      typeof measure.chart_config !== "object" ||
      Array.isArray(measure.chart_config)
    ) {
      measure.chart_config = {};
    }
    dispatch("ActionSetMeasure", measure);
  });
};

export const ActionStore = async ({ commit }, payload) => {
  await services.measures.store(payload).then(res => {
    commit(types.SET_MEASURE, res.data.measure);
  });
};

export const ActionUpdate = async ({ commit }, payload) => {
  await services.measures.update({ id: payload.id }, payload).then(res => {
    commit(types.SET_MEASURE, res.data.measure);
  });
};

export const ActionSave = async ({ dispatch, state }, payload) => {
  if (payload.id) {
    await dispatch("ActionUpdate", payload);
  } else {
    await dispatch("ActionStore", payload);
  }
  return state.measure;
};

export const ActionDelete = async ({ commit }, payload) => {
  await services.measures.delete({ id: payload.id }).then(() => {
    commit(types.SET_MEASURE, null);
  });
};

export const ActionSetMeasures = ({ commit }, payload) => {
  commit(types.SET_MEASURES, payload);
};

export const ActionSetMeasure = ({ commit }, payload) => {
  commit(types.SET_MEASURE, payload);
};

export const ActionSetPagination = ({ commit }, payload) => {
  commit(types.SET_PAGINATION, payload);
};

export const ActionChartData = async (store, payload) => {
  try {
    const { data } = await services.measures.chart_data(payload);
    return data;
  } catch (error) {
    return null;
  }
};

export const ActionNew = async ({ dispatch }) => {
  let measure = {
    name: "",
    description: "",
    metric: {},
    targets: [],
    chart_config: {}
  };
  dispatch("ActionSetMeasure", measure);
};
