import { routes as home } from "../pages/home";
import { routes as auth } from "../modules/auth";
import { routes as states } from "../modules/states";
import { routes as counties } from "../modules/counties";
import { routes as physical_people } from "../modules/physical_people";
import { routes as legal_people } from "../modules/legal_people";
import { routes as organizational_structures } from "../modules/organizational_structures";
import { routes_admin as strategic_plans } from "../modules/strategic_plans";
import { routes as objectives } from "../modules/objectives";
import { routes as measures } from "../modules/measures";
import { routes as clients } from "../modules/clients";
import { routes as acl } from "../modules/acl";
import { routes as users } from "../modules/users";
import { routes as indicators } from "../modules/indicators";
import { routes as unities } from "../modules/unities";
import { routes as action_plans } from '../modules/action_plans'

import { routes as planos_estrategicos } from "../modules/strategic_plans";
import { routes as ErrorPage } from "../pages/error";
import { routes as source } from "../modules/fontes";

export default [
  ...auth,
  ...home,
  ...states,
  ...counties,
  ...physical_people,
  ...legal_people,
  ...organizational_structures,
  ...strategic_plans,
  ...objectives,
  ...measures,
  ...planos_estrategicos,
  ...clients,
  ...acl,
  ...users,
  ...indicators,
  ...ErrorPage,
  ...unities,
  ...action_plans,
  ...source
];
