export default [
  {
    path: "/objectives",
    component: () => import("@/layout/Default"),
    children: [
      {
        name: "objectives_list",
        path: "",
        component: () => import("./pages/List"),
        meta: {
          breadcrumbs: [{ label: "Objetivos", to: "objectives_list" }],
          permission: "objective:view"
        }
      },
      {
        name: "objectives_show",
        path: "show/:id",
        component: () => import("./pages/Show"),
        meta: {
          title: "Objetivo",
          breadcrumbs: [
            {
              label: "Planos Estratégicos",
              to: "strategic_plans_show",
              params: {
                getter: "objectives/getObjective",
                id: "strategic_plan.id"
              }
            },
            {
              label: "Objetivos",
              to: "strategic_plans_show",
              params: {
                getter: "objectives/getObjective",
                id: "strategic_plan.id"
              },
              query: {
                showObjectives: true
              }
            },
            { label: "Visualizar", to: "objectives_show", active: true }
          ],
          permission: "objective:view"
        }
      },
      {
        name: "objectives_create",
        path: "create",
        component: () => import("./pages/Create"),
        meta: {
          title: "Novo objetivo",
          breadcrumbs: [
            { label: "Planos Estratégicos", to: "planos_estrategicos_list" },
            { label: "Objetivos", to: "objectives_list" },
            { label: "Novo", to: "objectives_create", active: true }
          ],
          permission: "objective:create"
        }
      },
      {
        name: "objectives_edit",
        path: "edit/:id",
        component: () => import("./pages/Edit"),
        meta: {
          title: "Editar",
          breadcrumbs: [
            {
              label: "Planos Estratégicos",
              to: "strategic_plans_show",
              params: {
                getter: "objectives/getObjective",
                id: "strategic_plan.id"
              }
            },
            {
              label: "Objetivos",
              to: "strategic_plans_show",
              params: {
                getter: "objectives/getObjective",
                id: "strategic_plan.id"
              },
              query: {
                showObjectives: true
              }
            },
            { label: "Editar", to: "objectives_edit", active: true }
          ],

          permission: "objective:edit"
        }
      }
    ]
  }
];
