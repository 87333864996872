export default {
  get_all: { method: "get", url: "api/action-plans" },
  get_action_plan: { method: "get", url: "api/action-plans{/id}" },
  delete_action_plan: { method: "delete", url: "api/action-plans{/id}" },
  get_plans_items: {
    method: "get",
    url: "api/action-plans-items",
  },
  store_how_much_items: {
    method: "post",
    url: "api/action-plans-how-much",
  },
  update_how_much_items: {
    method: "put",
    url: "api/action-plans-how-much{/id}",
  },
  store_plan_items: { method: "post", url: "api/action-plans-items" },
  update_plan_items: { method: "put", url: "api/action-plans-items{/id}" },
  store: { method: "post", url: "api/action-plans" },
  update: { method: "put", url: "api/action-plans{/id}" },
};
