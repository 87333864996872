import services from "@/http";
import store from "@/store";
import * as storage from "../storage";
import * as types from "./mutation-types";
const crypto = require("crypto");

export const ActionDoLogin = async ({ dispatch }, payload) => {
  await services.auth.login(payload).then((res) => {
    dispatch("ActionSetUser", res.data.user);
    dispatch("ActionSetToken", res.data.access_token);
  });
};

export const ActionCheckToken = ({ dispatch, state }) => {
  if (state.token) {
    return Promise.resolve(state.token);
  }

  const token = storage.getLocalToken();

  if (!token) {
    return Promise.reject(new Error("Token Inválido"));
  }

  dispatch("ActionSetToken", token);
  return dispatch("ActionLoadSession");
};

export const ActionLoadSession = ({ dispatch }) => {
  return new Promise(async (resolve, reject) => {
    try {
      const { data } = await services.auth.loadSession();
      dispatch("ActionSetUser", data.user);
      resolve();
    } catch (err) {
      dispatch("ActionSignOut");
      reject(err);
    }
  });
};

export const ActionSetUser = ({ commit }, payload) => {
  commit(types.SET_USER, payload);
};

export const ActionSetToken = ({ commit }, payload) => {
  storage.setLocalToken(payload);
  storage.setHeaderToken(payload);
  commit(types.SET_TOKEN, payload);
};

export const ActionSignOut = async ({ dispatch }) => {
  if (store.getters["auth/hasToken"]) {
    try {
      await services.auth.logout();
    } catch (errr) {
      Promise.reject(errr);
    }

    storage.setHeaderToken("");
    storage.deleteLocalToken();
    dispatch("ActionSetUser", {});
    dispatch("ActionSetToken", "");
  }
};

export const ActionRefresh = async ({ dispatch }) => {
  try {
    const { data } = await services.auth.refresh();
    await dispatch("ActionSetToken", data.access_token);
  } catch (e) {
    //console.log("E", e);
  }
};

export const ActionGenerateJwt = async ({ state }, sys) => {
  const header = {
    alg: "HS256",
    typ: "JWT",
  };
  let secretKey = null;

  switch (sys) {
    case "gesconselho":
      secretKey = process.env.VUE_APP_GESPLANOS_SECRET;
      break;
    case "infopolis":
      secretKey = process.env.VUE_APP_INFOPOLIS_SECRET;
      break;
    default:
      return "";
  }

  const payload = {
    origem: "gesplanos",
    user: state.user.email,
  };

  const base64Header = base64Encode(JSON.stringify(header));
  const base64Payload = base64Encode(JSON.stringify(payload));

  const signatureInput = `${base64Header}.${base64Payload}`;
  const signature = await hmacSHA256(secretKey, signatureInput);

  return `${signatureInput}.${base64Encode(signature)}`;
};

/*
async function hmacSHA256(key, message) {
  const encoder = new TextEncoder();
  const keyData = encoder.encode(key);
  const messageData = encoder.encode(message);

  const cryptoKey = await crypto.subtle.importKey(
    'raw',
    keyData,
    { name: 'HMAC', hash: 'SHA-256' },
    false,
    ['sign']
  );

  const signature = await crypto.subtle.sign('HMAC', cryptoKey, messageData);
  return Array.from(new Uint8Array(signature)).map(b => b.toString(16).padStart(2, '0')).join('');
}
*/
async function hmacSHA256(key, message) {
  return crypto
    .createHmac("sha256", key)
    .update(message)
    .digest("base64")
    .replace(/=/g, "")
    .replace(/\+/g, "-")
    .replace(/\//g, "_");
}

function base64Encode(str) {
  return Buffer.from(str).toString("base64");
}

function base64Decode(str) {
  return Buffer.from(str, "base64").toString();
}
