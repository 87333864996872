export default function(document) {
  if (document) {
    document = typeof document != "string" ? document.toString() : document;

    document = document.padStart(14, "0");
    document = document.replace(
      /^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/,
      "$1.$2.$3/$4-$5"
    );
  }

  return document;
}
