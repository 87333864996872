import { services as auth } from "@/modules/auth";
import { services as states } from "@/modules/states";
import { services as counties } from "@/modules/counties";
import { services as physical_people } from "@/modules/physical_people";
import { services as legal_people } from "@/modules/legal_people";
import { services as organizational_structures } from "@/modules/organizational_structures";
import { services as strategic_plans } from "@/modules/strategic_plans";
import { services as action_plans } from "@/modules/action_plans"
import { services as objectives } from "@/modules/objectives";
import { services as measures } from "@/modules/measures";
import { services as clients } from "@/modules/clients";
import { services as acl } from "@/modules/acl";
import { services as users } from "@/modules/users";
import { services as indicators } from "@/modules/indicators";
import { services as source } from "@/modules/fontes";
import { services as unities } from "@/modules/unities"

export default {
  auth,
  states,
  counties,
  physical_people,
  legal_people,
  organizational_structures,
  strategic_plans,
  action_plans,
  objectives,
  measures,
  clients,
  acl,
  users,
  indicators,
  source,
  unities
};
