/**
 * @type {import('vue-router').RouteConfig[]}
 */
export default [
  {
    path: '/action_plans',
    component: () => import('@/layout/Default.vue'),
    children: [
      {
        path: 'create',
        name: 'create_action_plan',
        component: () => import('./pages/Create.vue'),
        meta: {
          title: "Criar plano de ação",
          breadcrumbs: [
            { label: "Planos Municipais", to: "planos_estrategicos_list" },
            { label: "Novo plano de ação", to: "create_action_plan", active: true }
          ]
        }
      },
      {
        path: 'show/:id',
        name: 'show_action_plan',
        component: () => import('./pages/Show.vue'),
        meta: {
          title: "Plano de ação",
          breadcrumbs: [
            { label: "Planos Municipais", to: "planos_estrategicos_list" },
            { label: "Plano de ação", to: "show_action_plan", active: true }
          ]
        }
      },
    ]
  }
];
