export default [
  {
    path: "/strategic_plans",
    component: () => import("@/layout/Default"),
    children: [
      {
        name: "strategic_plans_list",
        path: "",
        component: () => import("./pages/List"),
        meta: {
          breadcrumbs: [
            { label: "Planos Municipais", to: "planos_estrategicos_list" }
          ],
          permission: "strategic_plan:view"
        }
      },
      {
        name: "strategic_plans_show",
        path: "show/:id",
        component: () => import("./pages/Show"),
        meta: {
          title: "Plano",
          breadcrumbs: [
            { label: "Planos Municipais", to: "planos_estrategicos_list" },
            { label: "Visualizar", to: "show", active: true }
          ],
          permission: "strategic_plan:view"
        }
      },
      {
        name: "strategic_plans_create",
        path: "create",
        component: () => import("./pages/Create"),
        meta: {
          breadcrumbs: [
            { label: "Planos Municipais", to: "planos_estrategicos_list" },
            { label: "Novo", to: "create", active: true }
          ],
          permission: "strategic_plan:create"
        }
      },
      {
        name: "strategic_plans_edit",
        path: "edit/:id",
        component: () => import("./pages/Edit"),
        meta: {
          breadcrumbs: [
            { label: "Planos Municipais", to: "planos_estrategicos_list" },
            { label: "Editar", to: "edit", active: true }
          ],
          permission: "strategic_plan:edit"
        }
      }
    ]
  }
];
