import services from "@/http";
import * as types from "./mutation-types";

export const ActionGetAll = async ({ dispatch }, payload) => {
  await services.objectives.get_all(payload).then(res => {
    dispatch("ActionSetOjectives", res.data.data);
    dispatch("ActionSetPagination", res.data.meta);
  });
};

export const ActionFind = async (store, payload) => {
  var response = [];
  await services.objectives.get_all(payload).then(res => {
    response = res.data.data;
  });
  return response;
};

export const ActionShow = async ({ dispatch }, payload) => {
  await services.objectives.show(payload).then(res => {
    dispatch("ActionSetOjective", res.data.objective);
  });
};

export const ActionGetMeasures = async (store, payload) => {
 try {
   const response = await services.objectives.get_measures(payload)
   return response.data
  } catch (error) {
   return {}
  }
}

export const ActionNew = async ({ dispatch }) => {
  let objective = {
    name: "",
    description: "",
    strategic_plan: {},
    indicators: [],
    measures: [],
    structure: [],
    plan_config: {}
  };
  dispatch("ActionSetOjective", objective);
};

export const ActionSave = async ({ dispatch }, payload) => {
  console.log('action save',payload.id)
  if (payload.id) {
    dispatch("ActionUpdate", payload);
  } else {
    dispatch("ActionStore", payload);
  }
};

export const ActionStore = async ({ commit }, payload) => {
  await services.objectives.store(payload).then(res => {
    commit(types.SET_OBJECTIVE, res.data.objective);
  });
};

export const ActionFindIndicadores = async ({ dispatch }, payload) => {
  await services.objectives.get_all(payload).then(res => {
    dispatch("ActionSetOjectives", res.data.data);
    dispatch("ActionSetPagination", res.data.meta);
  });
};

export const ActionUpdate = async ({ commit }, payload) => {
  await services.objectives.update({ id: payload.id }, payload).then(res => {
    commit(types.SET_OBJECTIVE, res.data.objective);
  });
};

export const ActionDelete = async ({ commit }, payload) => {
  await services.objectives.delete({ id: payload.id }).then(() => {
    commit(types.SET_OBJECTIVE, null);
  });
};

export const ActionSetOjectives = ({ commit }, payload) => {
  commit(types.SET_OBJECTIVES, payload);
};

export const ActionSetOjective = ({ commit }, payload) => {
  commit(types.SET_OBJECTIVE, payload);
};

export const ActionSetPagination = ({ commit }, payload) => {
  commit(types.SET_PAGINATION, payload);
};

export const ActionGetOds = async ({ commit }, payload) => {
  await services.objectives.get_ods(payload).then(res => {
    commit(types.SET_ODS, res.data);
  });
};

export const ActionVerifyIndicator = async (store, url) => {
  var response = {};
  await services.objectives
    .verify_indicator({ url: url })
    .then(res => {
      response = res.data;
    })
    .catch(() => {
      response = {};
    });

  return response;
};

export const ActionOrderMeasuresById = async (store, payload) => {
  await services.objectives.orderMeasuresById(
    { id: payload.id },
    payload.measures
  );
};
