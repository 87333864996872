import store from "@/store";

const ConfigStore = {
  client_id: "",
  login_text: "",
  login_subtext: "",
  login_logo_image: "",
  login_bg_image: "",
  county_id: "",
  ibge: "",
  indicadores: [],

  async loadConfig() {
    let config = await store.dispatch("clients/ActionLoadConfig");

    this.login_text = config.login_text;
    this.login_subtext = config.login_subtext;
    this.login_logo_image = config.login_logo_image;
    this.login_bg_image = config.login_bg_image;
    this.county_id = config.county_id;
    this.ibge = config.ibge;
    this.indicadores = config.indicadores;
  }
};

const ConfigPlugin = {
  install(Vue) {
    let app = new Vue({
      data: {
        configStore: ConfigStore
      },
      methods: {
        async config() {
          await this.configStore.loadConfig();
        }
      }
    });
    app.config();
    Vue.prototype.$config = app.configStore;
  }
};

export default ConfigPlugin;
