<template>
  <div class="datepicker">
    <b-form-input
      disabled
      v-if="disabled"
      :placeholder="placeholder"
      :size="size"
    />
    <flat-pickr
      v-model="local_value"
      :config="config"
      :placeholder="placeholder"
      :size="size"
      v-else
    />
    <div
      class="clear-value"
      :class="{ 'has-value': has_value }"
      @click="clearValue"
    >
      <font-awesome-icon icon="times-circle" />
    </div>
  </div>
</template>

<script>
import {
  formControlMixin,
  props as formControlProps,
} from "bootstrap-vue/esm/mixins/form-control";

import {
  formStateMixin,
  props as formStateProps,
} from "bootstrap-vue/esm/mixins/form-state";

import { sortKeys } from "bootstrap-vue/esm/utils/object";
import { makePropsConfigurable } from "bootstrap-vue/esm/utils/props";

import flatPickr from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";
import { Portuguese } from "flatpickr/dist/l10n/pt.js";

const props = makePropsConfigurable(
  sortKeys({
    ...formStateProps,
    ...formControlProps,
    value: "",
    placeholder: {
      type: String,
      default: "Selecione uma data",
    },
    size: {
      type: String,
      default: "md",
    },
  })
);

export default {
  components: { flatPickr },
  mixins: [formControlMixin, formStateMixin],
  props,
  data: () => ({}),
  computed: {
    local_value: {
      set(value) {
        this.$emit("input", value);
      },
      get() {
        return this.value;
      },
    },
    has_value() {
      return this.value ? true : false;
    },
    config() {
      return {
        wrap: true,
        altFormat: "d/m/Y",
        altInput: true,
        allowInput: true,
        dateFormat: "Y-m-d",
        locale: Portuguese,
        altInputClass: this.classes.join(" "),
      };
    },
    classes() {
      let classes = ["form-control", "datepicker"];
      if (this.stateClass) {
        classes.push(this.stateClass);
      }

      if (this.size != "md") {
        classes.push("form-control-" + this.size);
      }

      return classes;
    },
  },
  methods: {
    clearValue() {
      this.local_value = null;
    },
  },
  watch: {},
  async created() {},
};
</script>

<style lang="scss">
.datepicker {
  position: relative;

  .clear-value {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 0.75rem;
    cursor: pointer;
    opacity: 0;
    transition: all 0.3s;

    &.has-value {
      opacity: 1;
    }
  }
}
</style>
