export default [
    {
      path: "/",
      component: () => import("@/layout/Default"),
      children: [
        {
          name: "ErrorPage",
          path: "",
          component: () => import("./ErrorPage"),
          meta: {
            title: "Error Page",
            breadcrumbs: []
          }
        }
      ]
    }
  ];
  