<template>
  <b-input-group :class="[stateClass]" :size="size">
    <b-form-input
      v-model="text_value"
      type="text"
      :placeholder="placeholder"
      @blur="set_value"
      autocomplete="off"
    ></b-form-input>
    <b-input-group-append>
      <b-form-datepicker
        v-model="self_value"
        button-only
        right
        :size="size"
      ></b-form-datepicker>
    </b-input-group-append>
  </b-input-group>
</template>

<script>
import moment from "moment";
import {
  formControlMixin,
  props as formControlProps,
} from "bootstrap-vue/esm/mixins/form-control";

import {
  formStateMixin,
  props as formStateProps,
} from "bootstrap-vue/esm/mixins/form-state";

import { sortKeys } from "bootstrap-vue/esm/utils/object";
import { makePropsConfigurable } from "bootstrap-vue/esm/utils/props";

const props = makePropsConfigurable(
  sortKeys({
    ...formStateProps,
    ...formControlProps,
    value: "",
    placeholder: {
      type: String,
      default: "DD/MM/YYYY",
    },
    size: {
      type: String,
      default: "md",
    },
  })
);

export default {
  name: "DatePicker",
  props,
  mixins: [formControlMixin, formStateMixin],
  data: () => ({
    self_value: null,
  }),
  computed: {
    text_value: {
      get() {
        return this.$options.filters.format_date(this.self_value);
      },
      set() {},
    },
  },
  watch: {
    self_value: function (value, old) {
      if (value != old) {
        this.$emit("input", value);
      }
    },
  },
  methods: {
    set_value(obj) {
      let format = "D/M/Y";
      let value = obj.srcElement.value;

      this.self_value = moment(value, format).isValid()
        ? moment(value, format).format("YYYY-MM-DD")
        : null;
    },
  },
  created() {
    this.self_value = this.value;
  },
};
</script>

<style>
</style>
