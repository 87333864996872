export default {
  get_all: { method: "get", url: "api/strategic-plans" },
  show: { method: "get", url: "api/strategic-plans{/id}" },
  store: { method: "post", url: "api/strategic-plans" },
  update: { method: "put", url: "api/strategic-plans{/id}" },
  delete: { method: "delete", url: "api/strategic-plans{/id}" },
  tree_plan_structure: {
    method: "get",
    url: "api/plan-structures/tree-plan-structure{/id}"
  },
  get_objectives: {
    method: "get",
    url: "api/strategic-plans/get-objectives{/id}"
  },
  save_structure: {
    method: "post",
    url: "api/strategic-plans/save-structure{/id}"
  },
  orderObjectivesById: { method: "post", url: "api/objectives/order{/id}" },

  orderStrategicPlansById: {
    method: "post",
    url: "api/strategic-plans/order"
  },

  orderDiretrizesById: {
    method: "post",
    url: "api/plan-structures/order{/id}"
  }
};
