import services from "@/http";
import * as types from "./mutation-types";

export const ActionGetAll = async ({ dispatch }, payload) => {
  await services.counties.get_all(payload).then(res => {
    dispatch("ActionSetCounties", res.data.data);
    dispatch("ActionSetPagination", res.data.meta);
  });
};

export const ActionShow = async ({ dispatch }, payload) => {
  await services.counties.show(payload).then(res => {
    dispatch("ActionSetCounty", res.data.county);
  });
};

export const ActionFindById = async (store, id) => {
  var county = {};
  await services.counties.show({ id: id }).then(res => {
    county = res.data.county;
  });
  return county;
};

export const ActionStore = async ({ commit }, payload) => {
  await services.counties.store(payload).then(res => {
    commit(types.SET_COUNTY, res.data.county);
  });
};

export const ActionUpdate = async ({ commit }, payload) => {
  await services.counties.update(payload).then(res => {
    commit(types.SET_COUNTY, res.data.county);
  });
};

export const ActionDelete = async ({ commit }, payload) => {
  await services.counties.delete(payload).then(() => {
    commit(types.SET_COUNTY, null);
  });
};

export const ActionSetCounties = ({ commit }, payload) => {
  commit(types.SET_COUNTIES, payload);
};

export const ActionSetCounty = ({ commit }, payload) => {
  commit(types.SET_COUNTY, payload);
};

export const ActionSetPagination = ({ commit }, payload) => {
  commit(types.SET_PAGINATION, payload);
};


export const ActionFind = async (store, search) => {
  var counties = [];
  await services.counties.get_all({ q: search }).then(res => {
    counties = res.data.data;
  });
  return counties;
};
