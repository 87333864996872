import * as types from './mutation-types'

export default {
  [types.SET_PHYSICAL_PEOPLE] (state, payload) {
    state.physical_people = payload
  },
  [types.SET_PHYSICAL_PERSON] (state, payload) {
    state.physical_person = payload
  },
  [types.SET_PAGINATION] (state, payload) {
    state.pagination = payload
  }
}
