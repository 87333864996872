import * as types from './mutation-types'

export default {
  [types.SET_STATES] (state, payload) {
    state.states = payload
  },
  [types.SET_STATE] (state, payload) {

    state.state = payload
  },
  [types.SET_PAGINATION] (state, payload) {
    state.pagination = payload
  }
}
