export default [
  {
    path: "/counties",
    component: () => import("@/layout/Default"),
    children: [
      {
        name: "counties_list",
        path: "",
        component: () => import("./pages/List"),
        meta: {
          breadcrumbs: [{ label: "Cidades", to: "counties_list" }],
          permission: "county:view"
        }
      },
      {
        name: "counties_show",
        path: "show/:id",
        component: () => import("./pages/Show"),
        meta: {
          breadcrumbs: [
            { label: "Cidades", to: "counties_list" },
            { label: "Visualizar", to: "counties_show" }
          ],
          permission: "county:view"
        }
      },
      {
        name: "counties_create",
        path: "create",
        component: () => import("./pages/Create"),
        meta: {
          breadcrumbs: [
            { label: "Cidades", to: "counties_list" },
            { label: "Novo", to: "counties_create" }
          ],
          permission: "county:create"
        }
      },
      {
        name: "counties_edit",
        path: "edit/:id",
        component: () => import("./pages/Edit"),
        meta: {
          breadcrumbs: [
            { label: "Cidades", to: "counties_list" },
            { label: "Editar", to: "counties_edit" }
          ],
          permission: "county:edit"
        }
      }
    ]
  }
];
