import * as types from './mutation-types'

export default {
  [types.SET_LEGAL_PEOPLE] (state, payload) {
    state.legal_people = payload
  },
  [types.SET_LEGAL_PERSON] (state, payload) {
    state.legal_person = payload
  },
  [types.SET_PAGINATION] (state, payload) {
    state.pagination = payload
  }
}
