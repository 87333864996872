export default [
  {
    path: "/physical_people",
    component: () => import("@/layout/Default"),
    children: [
      {
        name: "physical_people_list",
        path: "",
        component: () => import("./pages/List"),
        meta: {
          title: "Pessoas Físicas",
          breadcrumbs: [
            { label: "Pessoas Físicas", to: "physical_people_list" }
          ],
          permission: "physical_people:view"
        }
      },
      {
        name: "physical_people_show",
        path: "show/:id",
        component: () => import("./pages/Show"),
        meta: {
          title: "Pessoas Físicas | Visualizar",
          breadcrumbs: [
            { label: "Pessoas Físicas", to: "physical_people_list" },
            { label: "Visualizar", to: "physical_people_show" }
          ],
          permission: "physical_people:view"
        }
      },
      {
        name: "physical_people_create",
        path: "create",
        component: () => import("./pages/Create"),
        meta: {
          title: "Pessoas Físicas | Cadastro",
          breadcrumbs: [
            { label: "Pessoas Físicas", to: "physical_people_list" },
            { label: "Novo", to: "physical_people_create" }
          ],
          permission: "physical_people:create"
        }
      },
      {
        name: "physical_people_edit",
        path: "edit/:id",
        component: () => import("./pages/Edit"),
        meta: {
          title: "Pessoa Física | Edição",
          breadcrumbs: [
            { label: "Pessoa Física", to: "physical_people_list" },
            { label: "Editar", to: "physical_people_edit" }
          ],
          permission: "physical_people:edit"
        }
      }
    ]
  }
];
