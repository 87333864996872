export default {
  get_all: { method: "get", url: "api/users" },
  get_all_roles: { method: "get", url: "api/roles" },
  show: { method: "get", url: "api/users{/id}" },
  store: { method: "post", url: "api/users" },
  update: { method: "put", url: "api/users{/id}" },
  delete: { method: "delete", url: "api/users{/id}" },
  verify: { method: "post", url: "api/users/verify" },
  join: { method: "post", url: "api/users/join{/id}" }
};
