import { store as auth } from "@/modules/auth";
import { store as states } from "@/modules/states";
import { store as counties } from "@/modules/counties";
import { store as physical_people } from "@/modules/physical_people";
import { store as legal_people } from "@/modules/legal_people";
import { store as organizational_structures } from "@/modules/organizational_structures";
import { store as strategic_plans } from "@/modules/strategic_plans";
import { store as objectives } from "@/modules/objectives";
import { store as measures } from "@/modules/measures";
import { store as clients } from "@/modules/clients";
import { store as acl } from "@/modules/acl";
import { store as users } from "@/modules/users";
import { store as indicators } from "@/modules/indicators";
import { store as source } from "@/modules/fontes";
import { store as unities } from "@/modules/unities";
import { store as action_plans } from "@/modules/action_plans";

export default {
  auth,
  states,
  counties,
  physical_people,
  legal_people,
  organizational_structures,
  strategic_plans,
  objectives,
  measures,
  clients,
  acl,
  users,
  indicators,
  unities,
  source,
  action_plans
};
