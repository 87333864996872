export default [
  {
    path: "/clients",
    component: () => import("@/layout/Default"),
    children: [
      {
        name: "clients_show",
        path: "",
        component: () => import("./pages/Show"),
        meta: {
          breadcrumbs: [
            { label: "Clientes", to: "clients_show" },
            { label: "Visualizar", to: "clients_show" }
          ],
          permission: "client_config:view"
        }
      },
      {
        name: "clients_edit",
        path: "edit/:id",
        component: () => import("./pages/Edit"),
        meta: {
          breadcrumbs: [
            { label: "Clientes", to: "clients_show" },
            { label: "Editar", to: "clients_edit" }
          ],
          permission: 'client_config:edit'
        }
      }
    ]
  }
];
