import services from "@/http";
import * as types from "./mutation-types";

export const ActionGetAll = async ({ dispatch }, payload) => {
  await services.indicators.get_all(payload).then(res => {
    dispatch("ActionSetIndicators", res.data.data);
    dispatch("ActionSetPagination", res.data.meta);
  });
};

export const ActionFind = async (store, payload) => {
  var response = [];
  await services.indicators.get_all(payload).then(res => {
    response = res.data;
  });
  return response;
};

export const ActionShow = async ({ dispatch }, payload) => {
  await services.indicators.show(payload).then(res => {
    dispatch("ActionSetIndicator", res.data.municipality_indicators);
  });
};

export const ActionFindById = async (store, id) => {
  var indicator = {};
  try {
    await services.indicators.show({ id: id }).then(res => {
      indicator = res.data.indicator;
    });
  } catch (e) {
    //ignore
  }
  return indicator;
};

export const ActionSave = async ({ dispatch }, payload) => {
  if (payload.id) {
    await dispatch("ActionUpdate", payload);
  } else {
    await dispatch("ActionStore", payload);
  }
};

export const ActionStore = async ({ commit }, payload) => {
  await services.indicators.store(payload).then(res => {
    commit(types.SET_INDICATOR, res.data.municipality_indicators);
  });
};

export const ActionUpdate = async ({ commit }, payload) => {
  await services.indicators.update({ id: payload.id }, payload).then(res => {
    commit(types.SET_INDICATOR, res.data.municipality_indicators);
  });
};

export const ActionDelete = async ({ commit }, id) => {
  await services.indicators.delete({ id: id }).then(() => {
    commit(types.SET_INDICATOR, null);
  });
};

export const ActionSetIndicators = ({ commit }, payload) => {
  commit(types.SET_INDICATORS, payload);
};

export const ActionSetIndicator = ({ commit }, payload) => {
  commit(types.SET_INDICATOR, payload);
};

export const ActionSetPagination = ({ commit }, payload) => {
  commit(types.SET_PAGINATION, payload);
};

export const ActionNew = async ({ dispatch }) => {
  let indicator = {
    name: "",
    description: "",
    source: "",
    unit: "",
    frequency: "",
    values: [
      {
        referenc_date: null,
        value: null,
        text: null
      }
    ]
  };
  dispatch("ActionSetIndicator", indicator);
};
