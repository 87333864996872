export default [
  {
    path: "/measures",
    component: () => import("@/layout/Default"),
    children: [
      {
        name: "measures_list",
        path: "",
        component: () => import("./pages/List"),
        meta: {
          breadcrumbs: [{ label: "Lista de metas", to: "measures_list" }],
          permission: "measure:view",
        },
      },
      {
        name: "measures_show",
        path: "show/:id",
        component: () => import("./pages/Show"),
        meta: {
          breadcrumbs: [
            { label: "Lista de metas", to: "measures_list" },
            { label: "Visualizar", to: "measures_show" },
          ],
          permission: "measure:view",
        },
      },
      {
        name: "measures_create",
        path: "create",
        component: () => import("./pages/Create"),
        meta: {
          breadcrumbs: [
            { label: "Lista de metas", to: "measures_list" },
            { label: "Novo", to: "measures_create" },
          ],
          permission: "measure:create",
        },
      },
      {
        name: "measures_edit",
        path: "edit/:id",
        component: () => import("./pages/Edit"),
        meta: {
          breadcrumbs: [
            { label: "Lista de metas", to: "measures_list" },
            { label: "Editar", to: "measures_edit" },
          ],
          permission: "measure:edit",
        },
      },
    ],
  },
];
