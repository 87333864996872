import moment from "moment";

export default function(date, template = "DD/MM/YYYY") {
  if (!date) {
    return template.replace(/[^/]/g, '-');
  }
  const string_date = date.split("T");
  return date
    ? moment(string_date[0]).format(template)
    : date;
}
