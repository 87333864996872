import Vue from "vue";

import cpf from "./cpf";
import cnpj from "./cnpj";
import pretty_json from "./pretty_json";
import camel_case from "./camel_case";
import format_date from "./format_date";
import number_format from "./number_format";

Vue.filter("cpf", cpf);
Vue.filter("cnpj", cnpj);
Vue.filter("pretty_json", pretty_json);
Vue.filter("camel_case", camel_case);
Vue.filter("format_date", format_date);
Vue.filter('number_format', number_format);
