import * as types from './mutation-types'

export default {
  [types.SET_ROLE] (state, payload) {
    state.role = payload
  },
  [types.SET_ROLES] (state, payload) {
    state.roles = payload
  },
  [types.SET_PAGINATION] (state, payload) {
    state.pagination = payload
  },

  [types.SET_ROLE_PERMISSIONS] (state, payload) {    
    state.permission = payload
  },
  
  [types.SET_PERMISSIONS] (state, payload) {
    state.permissions = payload
  },


}
