import * as types from './mutation-types'

export default {
  [types.SET_SOURCES] (state, payload) {
    state.sources = payload
  },
  [types.SET_SOURCE] (state, payload) {
    state.source = payload
  },
  [types.SET_PAGINATION] (state, payload) {
    state.pagination = payload
  }
}
