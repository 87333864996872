import services from "@/http";
import * as types from "./mutation-types";

export const ActionGetAll = async ({ dispatch }, payload) => {
  await services.acl.get_all(payload).then(res => {
    dispatch("ActionSetRoles", res.data.data);
    dispatch("ActionSetPagination", res.data.meta);
  });
};

export const ActionShow = async ({ dispatch }, payload) => {
  await services.acl.show(payload).then(res => {
    dispatch("ActionSetRole", res.data.role);
  });
};

export const ActionFindById = async (store, id) => {
  var person = {};
  try {
    await services.acl.show({ id: id }).then(res => {
      person = res.data.role;
    });
  } catch (e) {
    //ignore
  }
  return person;
};

export const ActionSave = async ({ dispatch }, payload) => {
  if (payload.id) {
    await dispatch("ActionUpdate", payload);
  } else {   
    await dispatch("ActionStore", payload);
  }
};

export const ActionStore = async ({ commit }, payload) => {
  await services.acl.store(payload).then(res => {
    commit(types.SET_ROLE, res.data.role);
  });
};

export const ActionUpdate = async ({ commit }, payload) => {
  await services.acl.update({ id: payload.id }, payload).then(res => {
    commit(types.SET_ROLE, res.data.role);
  });
};

export const ActionDelete = async ({ commit }, id) => {
  await services.acl.delete({ id: id }).then(() => {
    commit(types.SET_ROLE, null);
  });
};

export const ActionSetRoles = ({ commit }, payload) => {
  commit(types.SET_ROLES, payload);
};

export const ActionSetRole = ({ commit }, payload) => {
  commit(types.SET_ROLE, payload);
};

export const ActionSetPagination = ({ commit }, payload) => {
  commit(types.SET_PAGINATION, payload);
};

export const ActionFind = async (store, search) => {
  var responsibles = [];
  await services.roles.get_all({ q: search }).then(res => {
    responsibles = res.data.data;
  });
  return responsibles;
};

export const ActionNew = async ({ dispatch }) => {
  let role = {
    name: "",
    guard_name: "",
    created_at: "",
    updated_at: "",
    permission: []
  };
  dispatch("ActionSetRole", role);
};

export const ActionGetAllPermissions = async ({dispatch}, payload) => {
  await services.acl.get_all_permissions(payload).then(res => {   
    dispatch("ActionSetPermissions", res.data.data);    
  });
};

export const ActionSetPermissions = ({ commit }, payload) => {  
  commit(types.SET_PERMISSIONS, payload);
};

export const ActionSetRolePermissions = ({ commit }, payload) => {
  commit(types.SET_ROLE_PERMISSIONS, payload);
};


