import store from "../store";

export default () => {
  return ({ status }) => {
    if (status === 401) {
      store.dispatch("auth/ActionSignOut");
      if (window._Vue.$route.name != "login") {       
        window._Vue.$router.push({ name: "login" });
      }
    }
  };
};
