export default [
  {
    path: "/states",
    component: () => import("@/layout/Default"),
    children: [
      {
        name: "states_list",
        path: "",
        component: () => import("./pages/List"),
        meta: {
          breadcrumbs: [{ label: "Estados", to: "states_list" }],
          permission: "state:view"
        }
      },
      {
        name: "states_show",
        path: "show/:id",
        component: () => import("./pages/Show"),
        meta: {
          breadcrumbs: [
            { label: "Estados", to: "states_list" },
            { label: "Visualizar", to: "states_show" }
          ],
          permission: "state:show"
        }
      },
      {
        name: "states_create",
        path: "create",
        component: () => import("./pages/Create"),
        meta: {
          breadcrumbs: [
            { label: "Estados", to: "states_list" },
            { label: "Novo", to: "states_create" }
          ],
          permission: "state:create"
        }
      },
      {
        name: "states_edit",
        path: "edit/:id",
        component: () => import("./pages/Edit"),
        meta: {
          breadcrumbs: [
            { label: "Estados", to: "states_list" },
            { label: "Editar", to: "states_edit" }
          ],
          permission: "state:edit"
        }
      }
    ]
  }
];
