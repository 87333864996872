export default [
  {
    path: "/users",
    component: () => import("@/layout/Default"),
    children: [
      {
        name: "users_list",
        path: "",
        component: () => import("./pages/List"),
        meta: {
          title: "Usuários",
          breadcrumbs: [{ label: "Usuários", to: "users_list" }],
          permission: "user:view"
        }
      },
      {
        name: "users_show",
        path: "show/:id",
        component: () => import("./pages/Show"),
        meta: {
          title: "Usuários",
          breadcrumbs: [
            { label: "Usuários", to: "users_list" },
            {
              label: "Visualizar",
              to: "show",
              params: {
                getter: "users/GetUser",
                id: "user.id"
              }
            }
          ],
          permission: "user:view"
        }
      },
      {
        name: "users_create",
        path: "create",
        component: () => import("./pages/Create"),
        meta: {
          title: "Usuários | Cadastro de usuário",
          breadcrumbs: [
            { label: "Usuários", to: "users_list" },
            { label: "Novo", to: "create" }
          ],
          action: "Criando usuário",
          permission: "user:create"
        }
      },
      {
        name: "users_edit",
        path: "edit/:id",
        component: () => import("./pages/Edit"),
        meta: {
          title: "Usuários | Edição de usuário",
          breadcrumbs: [
            { label: "Usuários", to: "users_list" },
            {
              label: "Editar",
              to: "edit",
              params: {
                getter: "users/GetUser",
                id: "user.id"
              }
            }
          ],
          action: "Editando usuário",
          permission: "user:edit"
        }
      }
    ]
  },
  {
    path: "/profile",
    component: () => import("@/layout/Default"),
    children: [
      {
        name: "profile",
        path: "",
        component: () => import("./pages/Profile"),
        meta: {
          title: "Perfil",
          breadcrumbs: [{ label: "Profile", to: "profile" }],
        }
      },
    ]
  }
];
