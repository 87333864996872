import store from "@/store";
import { debounce } from "lodash";

const refreshAuth = debounce(() => {
  console.log("refreshAuth");
  store.dispatch("auth/ActionRefresh");
  console.log(store.state.auth.token);
}, 600000);

export default async function({ next, to }) {
  if (to.name !== "login" && !store.getters["auth/hasToken"]) {
    try {
      await store.dispatch("auth/ActionCheckToken");
      next();
    } catch (err) {
      next({ name: "login", query: { to: to.fullPath } });
    }
  } else {
    if (to.name === "login" && store.getters["auth/hasToken"]) {
      next({ name: "home" });
    } else {
      refreshAuth();
      next();
    }
  }
}
