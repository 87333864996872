import services from "@/http";
import * as types from "./mutation-types";

export const ActionGetAll = async ({ dispatch }, payload) => {
  await services.organizational_structures.get_all(payload).then(res => {
    dispatch("ActionSetOrganizationalStructures", res.data.data);
    dispatch("ActionSetPagination", res.data.meta);
  });
};

export const ActionShow = async ({ dispatch }, payload) => {
  await services.organizational_structures.show(payload).then(res => {
    dispatch("ActionSetStructureItem", res.data.organizational_structure);
  });
};

export const ActionSave = async ({ dispatch }, payload) => {
  if (payload.id) {
    await dispatch("ActionUpdate", payload);
  } else {
    await dispatch("ActionStore", payload);
  }
};

export const ActionStore = async ({ commit }, payload) => {
  await services.organizational_structures.store(payload).then(res => {
    commit(types.SET_STRUCTURE_ITEM, res.data.organizational_structure);
  });
};

export const ActionUpdate = async ({ commit }, payload) => {
  await services.organizational_structures
    .update({ id: payload.id }, payload)
    .then(res => {
      commit(types.SET_STRUCTURE_ITEM, res.data.organizational_structure);
    });
};

export const ActionDelete = async ({ commit }, payload) => {
  await services.organizational_structures.delete(payload).then(() => {
    commit(types.SET_STRUCTURE_ITEM, null);
  });
};

export const ActionSetOrganizationalStructures = ({ commit }, payload) => {
  commit(types.SET_ORGANIZATIONAL_STRUCTURES, payload);
};

export const ActionSetStructureItem = ({ commit }, payload) => {
  commit(types.SET_STRUCTURE_ITEM, payload);
};

export const ActionSetPagination = ({ commit }, payload) => {
  commit(types.SET_PAGINATION, payload);
};

export const ActionFind = async (store, search) => {
  var organizational_structures = [];
  await services.organizational_structures.get_all({ q: search }).then(res => {
    organizational_structures = res.data.data;
  });
  return organizational_structures;
};

export const ActionTreeStructure = async ({ commit }) => {
  await services.organizational_structures.tree_structure().then(res => {
    commit(types.SET_TREE_STRUCTURE, res.data);
  });
};

export const ActionAddPhysicalPeople = async ({ dispatch }, payload) => {
  await services.organizational_structures
    .add_physical_people({ id: payload.id }, payload)
    .then();
  dispatch("ActionTreeStructure");
};

export const ActionRemovePhysicalPeople = async ({ dispatch }, payload) => {
  await services.organizational_structures
    .delete_physical_people({ id: payload.id }, payload)
    .then();
  dispatch("ActionTreeStructure");
};

export const ActionNew = async ({ dispatch }) => {
  let structure_item = {
    id: null,
    name: "",
    description: "",
    parent_id: null,
    legal_person_id: null
  };
  dispatch("ActionSetStructureItem", structure_item);
};
