import services from "@/http";
import * as types from "./mutation-types";

export const ActionGetAll = async ({ dispatch }, payload) => {
  await services.physical_people.get_all(payload).then((res) => {
    dispatch("ActionSetPhysicalPeople", res.data.data);
    dispatch("ActionSetPagination", res.data.meta);
  });
};

export const ActionShow = async ({ dispatch }, payload) => {
  await services.physical_people.show(payload).then((res) => {
    dispatch("ActionSetPhysicalPerson", res.data.physical_person);
  });
};

export const ActionFindById = async (store, id) => {
  var person = {};
  try {
    await services.physical_people.show({ id: id }).then((res) => {
      person = res.data.physical_person;
    });
  } catch (e) {
    //ignore
  }
  return person;
};

export const ActionSave = async ({ dispatch }, payload) => {
  if (payload.id) {
    await dispatch("ActionUpdate", payload);
  } else {
    await dispatch("ActionStore", payload);
  }
};

export const ActionStore = async ({ commit }, payload) => {
  await services.physical_people.store(payload).then((res) => {
    commit(types.SET_PHYSICAL_PERSON, res.data.physical_person);
  });
};

export const ActionUpdate = async ({ commit }, payload) => {
  await services.physical_people
    .update({ id: payload.id }, payload)
    .then((res) => {
      commit(types.SET_PHYSICAL_PERSON, res.data.physical_person);
    });
};

export const ActionDelete = async ({ commit }, id) => {
  await services.physical_people.delete({ id: id }).then(() => {
    commit(types.SET_PHYSICAL_PERSON, null);
  });
};

export const ActionSetPhysicalPeople = ({ commit }, payload) => {
  commit(types.SET_PHYSICAL_PEOPLE, payload);
};

export const ActionSetPhysicalPerson = ({ commit }, payload) => {
  commit(types.SET_PHYSICAL_PERSON, payload);
};

export const ActionSetPagination = ({ commit }, payload) => {
  commit(types.SET_PAGINATION, payload);
};

export const ActionFind = async (store, search) => {
  var responsibles = [];
  await services.physical_people.get_all({ q: search }).then((res) => {
    responsibles = res.data.data;
  });
  return responsibles;
};

export const ActionNew = async ({ dispatch, getters }) => {
  let physical_person = {
    name: "",
    cpf: "",
    rg: "",
    date_birth: "",
    address: {
      street: "",
      number: "",
      neighborhood: "",
      zip_code: "",
      complement: null,
      county_id: null,
    },
    contacts: getters.getContactsEmpty,
  };
  dispatch("ActionSetPhysicalPerson", physical_person);
};
